import React from 'react'
import {FaFacebookF, FaLinkedinIn} from 'react-icons/fa';
import './footer.scss';
import {Col, Container, Row} from 'react-bootstrap';
import Logo from "../../../../../assets/website-images/utrust-logo.png";
import {GoArrowRight} from "react-icons/go";
import {AiFillInstagram} from "react-icons/ai";
import {BsTwitterX} from "react-icons/bs";

export default function Footer() {
    return (
        <div className="footer">
            <div className={'row-1'}>
                <Container>
                    <Row>
                        <Col xs={6} sm={6} md={4} lg={4} className={'d-none d-lg-block'}>
                            <ul className={'f-row'}>
                                <li>
                                    <a href="">About</a>
                                </li>
                                <li>
                                    <a href="">Company</a>
                                </li>
                                <li>
                                    <a href="">Services</a>
                                </li>
                                <li>
                                    <a href="">Resources</a>
                                </li>
                                <li>
                                    <a href="">Contact Us</a>
                                </li>
                            </ul>
                        </Col>
                        {/* <Col sm={0} lg={1} /> */}
                        <Col sm={6} md={4} lg={4} className={'text-center'}>
                            <img className={'mb-4'} src={Logo}/>
                        </Col>
                        {/* <Col sm={6} md={4} lg={1}/> */}
                        <Col lg={4}>
                            <h3 className='newsletter-heading'>Stay Connected with Our Newsletter for Exclusive Updates</h3>
                            <form>
                                <div className="email-input">
                                    <input type="email" name="email" id="" placeholder="Enter email"/>
                                    <span className={'arrow-right-icon'}><GoArrowRight /></span>
                                </div>
                            </form>
                        </Col>

                    </Row>

                </Container>
            </div>
            <div className={'row02'}>
                <Container fluid className={''}>
                    <Row>
                        <Col>
                            <div className="footer-info" >
                                <p><span className='teal'>Address</span>: Saudi Arabia - Riyadh - Al Nakheel 12883</p>
                                <div>
                                    <p><span className='teal'>Phone</span>: +96650 248 2148</p>
                                    <p className='teal'>Email Address: project@utrust.sa</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="footer-copyright">
                                <p>Copyright &copy; 2024 <span className='teal'>uTrust</span>. All Rights Reserved</p>
                                <div style={{display:"flex", gap:"20px", flexWrap:"wrap"}}>
                                    <span><FaFacebookF /></span>
                                    <span><AiFillInstagram /></span>
                                    <span><BsTwitterX /></span>
                                    <span><FaLinkedinIn /></span>
                                </div>
                                <div className='terms'>
                                    <ul>
                                        <li>
                                            <a href="">Terms & Conditions</a>
                                        </li>
                                        <li>
                                            <a href="">Privacy Policy</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

        </div>
    );
}
