import React, {useRef, useState} from "react"
import productPageImg from "../../../../../assets/website-images/product/iam/iam-product-img.png"
import productBannerImg from "../../../../../assets/website-images/product/product-banner.jpg";
import {useInView} from "framer-motion";
import PageBanner from "../../../../components/website/pages-banner/PageBanner";
import {Col, Container, Row} from "react-bootstrap";

import "./iam.scss"
import sec2box1 from "../../../../../assets/website-images/product/iam/box/b1.png";
import sec2box2 from "../../../../../assets/website-images/product/iam/box/b2.png";
import sec2box3 from "../../../../../assets/website-images/product/iam/box/b3.png";
import sec2box4 from "../../../../../assets/website-images/product/iam/box/b4.png";
import sec2box5 from "../../../../../assets/website-images/product/iam/box/b5.png";
import sec3Img from "../../../../../assets/website-images/product/iam/sec3.png";


export default function IAMPage() {
    const ref3 = useRef(null);
    const ref = useRef(null);
    const isInView3 = useInView(ref3, { once: true });
    const isInView = useInView(ref, { once: true }); // Only trigger once
    const [activeSection, setActiveSection] = useState(1);
    const containerVariants2 = {
        hidden: { opacity: 1 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.3, // Staggering the animation of each child by 0.3 seconds
            },
        },
    };
    const cardVariants = {
        hidden: { opacity: 0, x: -100 }, // Start with opacity 0 and 100px to the left
        show: { opacity: 1, x: 0 },      // Fade in and move to original position
    };


    const handleSectionClick = (sectionIndex) => {
        setActiveSection(sectionIndex);
    };

    return(
        <div className={'iam-page'}>
            <PageBanner img={productPageImg} heading={'uTrust IAM'} bannerImg={productBannerImg}/>
            <div className={'section-1'}>
                <Container>
                    <Row>
                        <Col>
                            <h2 className={'heading mb-3'}>Empower Your Enterprise with uTrust IAM</h2>
                            <h5 className={'mb-3'}>Elevating Access Management to New Heights with Unparalleled Security and Efficiency.</h5>
                            <p>uTrust IAM is an in-house developed solution by uTrust, meticulously designed to revolutionize how businesses manage and secure their digital identities. By integrating cutting-edge technology and robust security protocols, uTrust IAM ensures that only authorized users have access to sensitive data and applications, safeguarding your enterprise from potential threats.</p>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className={'section-2'}>
                <Container>
                    <Row>
                        <h3>Key Features:</h3>
                    </Row>
                    <Row>
                        <Col lg={4}>
                            <div className={'d-box'}>
                                <img src={sec2box1}/>
                                <h4>Multi-Factor Authentication (MFA)</h4>
                                <p>Enhance security by requiring multiple forms of verification to access your  systems, reducing the risk of unauthorized access.</p>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className={'d-box'}>
                                <img src={sec2box2}/>
                                <h4>Single Sign-On (SSO)</h4>
                                <p>Streamline the login process for users by enabling access to multiple applications with a single set of credentials, improving productivity and user satisfaction.</p>
                            </div>

                        </Col>
                        <Col lg={4}>
                            <div className={'d-box'}>
                                <img src={sec2box3}/>
                                <h4>User Lifecycle Management</h4>
                                <p>Automate the onboarding and offboarding processes, ensuring that user access rights are promptly updated based on their current status within the organization.</p>
                            </div>

                        </Col>
                        <Col lg={4}>
                            <div className={'d-box'}>
                                <img src={sec2box4}/>
                                <h4>AI-Powered Analytics</h4>
                                <p> Adapt to any business environment with a flexible solution that grows with your needs. Whether you are a small business or a large enterprise, uTrust Digital Identity can be tailored to meet your specific requirements.</p>
                            </div>

                        </Col>
                        <Col lg={4}>
                            <div className={'d-box'}>
                                <img src={sec2box5}/>
                                <h4>Compliance Management</h4>
                                <p>Ensure your organization meets regulatory requirements with built-in compliance tools that simplify reporting and auditing.</p>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>

            <div className={'section-3'}>
                <Container>
                    <Row>
                        <Col lg={6}>
                            <img src={sec3Img} className={'img-fluid'}/>
                        </Col>
                        <Col lg={6}>
                            <h2 className={'heading mb-4'}>Why Choose uTrust IAM?</h2>
                            <p>Developed entirely in-house by uTrust, our IAM solution embodies our commitment to excellence and innovation. With features like adaptive authentication and risk-based access controls, uTrust IAM provides a comprehensive security framework to protect your enterprise. Whether you are a small business or a large enterprise, uTrust IAM scales to meet your needs, offering flexible deployment options including cloud-based and on-premises solutions. </p>
                            <p>Our solution seamlessly integrates with your existing IT infrastructure, including popular directory services and cloud platforms, ensuring a smooth transition and minimal disruption to your operations. Designed with the end-user in mind, uTrust IAM offers an intuitive interface that simplifies identity and access management tasks for both administrators and users. </p>
                            <p>By choosing uTrust IAM, you empower your organization with a robust, efficient, and secure identity management solution that keeps pace with the ever-evolving cybersecurity landscape. Join the ranks of forward-thinking enterprises that trust uTrust IAM to safeguard their digital assets and enhance their operational efficiency.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}