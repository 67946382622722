import React from "react"


export default function Prac() {


    // let num = [1,2,3,4,5]
    // let num_more = [11,12,13,14,15]
    // let num_even_miore = [21,22,23,24,25,25]

    //delte
    // delete num[0]
    // console.log(num.length, 'num delete')
    // console.log(num, 'num delete')

    //concat
    // let arr = num.concat(num_more)


    // sort
    //
    // let num = [11,332,23,4,5]
    // let num1 = ['a', 'b', 'c', 'z', 'd', 'x', '6', 99, 'b', 'c', 111]
    //
    // let compare = (a,b)=>{
    //     return a-b
    // }
    //
    // let gg= num1.sort(compare)
    //
    // console.log(num1, 'sort')
    // console.log(gg, 'sort')

    //Splice and Slice

    // let num = [1,2,3,4,5,6,7,8]
    // console.log(num,'splice')
    // let deleted = num.splice(2,3,11,22,33,44,55)
    //
    // console.log(num,'splice')
    // console.log(deleted,'deleted splice', typeof deleted)

    // let change = num.slice(3,5)
    //
    // console.log(num, 'slice')
    // console.log(change, 'slice')


    // let num = [1,2,3,4,5,6]
    //
    // for (let i=0;i< num.length; i++){
    //     console.log(num[i])
    // }
    //
    // num.forEach((i)=>{
    //     console.log(i * i)
    // })
    //
    // function normalFunction() {
    //     console.log(this); // Depends on how it's called
    // }
    //
    // const arrowFunction = () => {
    //     console.log(this); // Inherits from surrounding scope
    // }
    //
    // let a = 2
    // let b= '2'
    //
    // let add = b == a
    //
    // console.log(add, 'add')


    // practive


    let arr = [1,2,3,4,5,6]


    function isPalindrome(num) {
        // Convert the number to a string
        const str = num.toString();
        console.log(str.split('').reverse().join(''), 'split')
        console.log(num, 'num')
        // Reverse the string
        const reversedStr = str.split('').reverse().join('');

        // Check if the original string is the same as the reversed string
        return str === reversedStr;
    }
    isPalindrome(121)

    return(
        <div>
            <h1>Practice</h1>
        </div>
    )
}